import React from "react";
import "./HomeBanner.css";
import {Col, Row} from "antd";
import {Link} from "react-router-dom";

/**
 * 商务合作/产品|求职
 * @author shawn
 */
export default class HomeBanner extends React.Component {

    render(): React.ReactNode {
        let imgJob = require("../../assets/images/home/center_ad/img_job.png")
        let imgProduct = require("../../assets/images/home/center_ad/img_product.png")
        let imgCooperation = require("../../assets/images/home/center_ad/img_cooperation.png")
        let imgCooperationMobile = require("../../assets/images/home/center_ad/img_cooperation_mobile.png")
        let imgProductMobile = require("../../assets/images/home/center_ad/img_product_mobile.png")
        let imgJobMobile = require("../../assets/images/home/center_ad/img_job_mobile.png")

        return (
            <div id="HomeBanner">
                <div className={"container"}>
                    <Row justify={"center"}  align={"middle"}>

                        <Col span={0} md={8}>
                            <Link to="/job">
                                <div className="HomeBanner-image-container">
                                    <img src={imgJob} alt=""></img>
                                </div>
                            </Link>
                        </Col>

                        <Col span={12} md={0}>
                            <Link to="/job">
                                <div className="HomeBanner-image-container">
                                    <img src={imgJobMobile} alt=""></img>
                                </div>
                            </Link>
                        </Col>

                        <Col span={12} md={16}>

                            <Row justify={"center"} align={"middle"}>

                                <Col md={12} span={0}>
                                    <Link to="/product">
                                        <div className="HomeBanner-image-container">
                                            <img src={imgProduct} alt=""></img>
                                        </div>
                                    </Link>
                                </Col>

                                <Col md={12} span={0}>
                                    <Link to="/cooperation">
                                        <div className="HomeBanner-image-container">
                                            <img src={imgCooperation} alt=""></img>
                                        </div>
                                    </Link>
                                </Col>

                                <Col md={0} span={24}>
                                    <Link to="/product">
                                        <div className="HomeBanner-image-container">
                                            <img src={imgProductMobile} alt=""></img>
                                        </div>
                                    </Link>
                                </Col>

                                <Col md={0} span={24}>
                                    <Link to="/cooperation">
                                        <div className="HomeBanner-image-container">
                                            <img src={imgCooperationMobile} alt=""></img>
                                        </div>
                                    </Link>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}