
export function BaseDef<T>(converter: ConvertOption<T>): DefConstructor<T> {
    for (let key in converter) {
        const fn = converter[key];
        if (typeof fn === "function") {
            //@ts-ignore
            converter[key] = [key, fn];
        }
    }

    return class {
        [key: string]: any

        constructor(rawJson: RawJson) {
            for (let key in converter) {

                try {
                    const c = converter[key] as ConvertAnotherKey<T>;
                    this[key] = c[1](rawJson[c[0]]);
                } catch (e) {
                        // console.error(`解析配置文件出错 ${key}:`, e);
                }
            }
        }
    } as DefConstructor<T>;
}

export type RawJson = { [key: string]: string }

export type ConvertFn<T> = (str: string) => T
export type ConvertAnotherKey<T> = [string, ConvertFn<T>]
export type Converter<T> = ConvertAnotherKey<T> | ConvertFn<T>

export type DefConstructor<T> = new (rawJson: { [key: string]: string }) => T
export type Accessors<T> = { [K in keyof T]: Converter<T[K]> }
export type ConvertOption<T> = Accessors<T> & Record<string, Converter<any>>