import React from "react";
import "./CompanyProfile.css";
import imgCompanyLogo from "../../assets/images/ui/company_logo.png";
import {Col, Row} from "antd";
import {PageFirstDef} from "../../config/PageFirstDef";
import {pageFirstConfig} from "../../config/xls";

/**
 * 公司简介
 * @author shawn
 */
class CompanyProfile extends React.Component<any, any> {

    render() {

        let def:PageFirstDef|undefined = pageFirstConfig.getItem(1);
        if (!def) {
            return <div></div>
        }

        let elementList:any = [];
        def.desc.split("|").forEach((value, index) => {
           elementList.push(
               <p className={"company-desc"} key={`${index}`}>
                   {value.trim()}
               </p>
           )
        });

        return (
            <div id="company-profile">
                <div className={"container"}>

                    <div style={{
                        fontSize: "0.24rem"
                    }} className={"global-title-text"}>公司简介</div>

                    <div style={{paddingTop: "0.2rem"}}>
                        <Row justify={"space-between"}
                             gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },{ xs: 8, sm: 16, md: 24, lg: 32 }]}
                             // md-align={"top"}
                             align={"top"}
                        >
                            <Col span={10} md={7} >
                                <div style={{textAlign: "center", verticalAlign: "middle"}}>
                                    <img src={imgCompanyLogo} alt={""}  style={{maxWidth:"100%"}}></img>
                                </div>
                            </Col>
                            <Col span={14} md={17} >
                                <p style={{
                                    fontSize: "0.24rem",
                                    fontWeight: "bold",
                                    color: "black"
                                }}>{def.title}</p>
                                {elementList}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default CompanyProfile;



