import {BaseDef} from "../config/BaseDef";
import {number, string} from "./Converters";


/**
 * 首页配置
 * @author shawn
 */
export class PageFirstDef extends BaseDef({
        id: number(),
        title: string(),
        desc: string(),
        source: string(),
    },
) {

}