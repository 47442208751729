/**
 * Created by deemo on 2018/6/19.
 */
export class Definitions<V> {
    private readonly _data: { [key: string]: V, [key: number]: V } = {};
    private _length: number = 0;

    get length(): number {
        return this._length;
    }

    addItem(id: string | number, def: V): void {
        if (!(id in this._data)) {
            this._length += 1;
        }
        this._data[id] = def;
    }

    getItem(id: string | number): V | undefined {
        return this._data[id];
    }

    find<S extends V>(predicate: (value: V) => value is S, thisArg?: any): S | undefined
    find(predicate: (value: V) => boolean, thisArg?: any): V | undefined
    find(predicate: (value: V) => boolean, thisArg?: any) {
        const a = this._data;
        for (let k in a) {
            if (predicate.call(thisArg, a[k])) {
                return a[k];
            }
        }
    }

    filter(p: (item: V) => boolean): V[]
    filter<T>(p: (item: V) => boolean, mapFn: (item: V) => T): T[]
    filter<T>(p: (item: V) => boolean, mapFn?: (item: V) => T) {
        const a = this._data;
        if (mapFn) {
            const r: T[] = [];
            for (let k in a) if (p(a[k])) r.push(mapFn(a[k]));
            return r;
        } else {
            const r: V[] = [];
            for (let k in a) if (p(a[k])) r.push(a[k]);
            return r;
        }
    }

    some(p: (item: V) => boolean): boolean {
        const a = this._data;
        for (let k in a) {
            if (p(a[k])) return true;
        }
        return false;
    }

    toArray(sortFn?: (a: V, b: V) => number): V[] {
        const arr = [];
        const a = this._data;
        for (let k in a) {
            arr.push(a[k]);
        }
        return sortFn ? arr.sort(sortFn) : arr;
    }

    forEach(fn: (v: V) => void) {
        const a = this._data;
        for (let k in a) {
            fn(a[k]);
        }
    }

    groupBy<T extends keyof V>(key: T): Map<V[T], V[]> {
        const typeMap = new Map<V[T], V[]>();
        const data = this._data;
        for (let k in data) {
            const v = data[k];
            const type = v[key];
            if (typeMap.has(type)) {
                typeMap.get(type)!.push(v);
            } else {
                typeMap.set(type, [v]);
            }
        }
        return typeMap;
    }

    * values(): IterableIterator<V> {
        const a = this._data;
        for (let k in a) {
            yield a[k];
        }
    }
}