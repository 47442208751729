/**
 * Created by deemo on 2018/7/31.
 */
export type Converter<T> = (str: string | undefined) => T

// namespace Converters { // 统一起见 所有的 Converter 都是由函数返回的
    export function number(): Converter<number> {
        // if (CC_DEBUG) {
        //     return str => {
        //         if (str) return Number(str);
        //         else throw new Error("缺少字段");
        //     };
        // } else {
            return number.default(NaN);
        // }
    }

    number.default = (defaultValue: number): Converter<number> => str => str ? Number(str) : defaultValue;
    number.optional = (): Converter<number | undefined> => str => str ? Number(str) : undefined;


    export function string(): Converter<string> {
        // if (CC_DEBUG) {
        //     return str => {
        //         if (str) return String(str);
        //         else throw new Error("缺少字段");
        //     };
        // } else {
            return string.default("");
        // }
    }

    string.default = (defaultValue: string): Converter<string> => str => str ? String(str) : defaultValue;
    string.optional = (): Converter<string | undefined> => str => str;


    export function boolean(): Converter<boolean> {
        // if (CC_DEBUG) {
        //     return str => {
        //         if (str) return str !== "false" && str !== "0";
        //         else throw new Error("缺少字段");
        //     };
        // } else {
            return boolean.default(false);
        // }
    }

    boolean.default = (defaultValue: boolean): Converter<boolean> => str => str ? str !== "false" && str !== "0" : defaultValue;
    boolean.optional = (): Converter<boolean | undefined> => str => str ? str !== "false" && str !== "0" : undefined;


    export function splitToArray<T>(separator: string, converter: (str: string) => T): Converter<T[]> {
        // if (CC_DEBUG) {
        //     return str => {
        //         if (str) return str.split(separator).map(converter);
        //         else throw new Error("缺少字段");
        //     };
        // } else {
            return splitToArray.default(separator, converter, () => ([]));
        // }
    }

    splitToArray.default = (separator: string, converter: (str: string) => any, d: () => any): Converter<any> => {
        return str => str ? str.split(separator).map(converter) : d();
    };
    // splitToArray.optional = <T>(separator: string, converter: (str: string) => T): Converter<T[] | undefined> => {
    //     return str => str ? str.split(separator).map(converter) : undefined;
    // };


    export function numberArray(separator: string): Converter<number[]> {
        return splitToArray(separator, number());
    }

    numberArray.default = function (separator: string, d: () => number[]): Converter<number[] | undefined> {
        return splitToArray.default(separator, number(), d);
    };
    // numberArray.optional = function (separator: string): Converter<number[] | undefined> {
    //     return splitToArray.optional(separator, number());
    // };

    export function date() {
        // if (CC_DEBUG) {
        //     return str => {
        //         if (str) return new Date(str);
        //         else throw new Error("缺少字段");
        //     };
        // } else {
            return date.default(() => new Date());
        // }
    }

    date.default = (d: () => Date): Converter<Date> => str => str ? new Date(str) : d();
    date.optional = (): Converter<Date | undefined> => str => str ? new Date(str) : undefined;


    export function numberEnum<E extends number>(): Converter<E> {
        return str => Number(str) as E;
    }

    numberEnum.default = <E extends number>(d: E): Converter<E> => str => (Number(str) || d) as E;
    numberEnum.optional = <E extends number>(): Converter<E | undefined> => str => str ? Number(str) as E : undefined;


    export function stringEnum<E extends string>(): Converter<E> {
        return str => str as E;
    }

    stringEnum.default = <E extends string>(d: E): Converter<E> => str => (str || d) as E;
    stringEnum.optional = <E extends string>(): Converter<E | undefined> => str => str ? str as E : undefined;
// }
//
// export default Converters;
