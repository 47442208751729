import React from "react";
import "./OurAdvantage.css";
import {Col, Row} from "antd";
import {pageCooperationConfig} from "../../config/xls";

/**
 * 我们的优势
 * @author shawn
 */
export default class OurAdvantage extends React.Component {

    render(): React.ReactNode {
        let def = pageCooperationConfig.getItem(2);

        if (!def) {
            return <div></div>
        }

        let elementList = [];

        for (let i = 0; i < 4; i++) {

            let subDef = def.subDesc[i].split("-");

            let img = require(`../../assets/images/cooperation/${i+34}.png`);

            elementList.push(
                <Col span={12} md={6} key={`${i}`}>
                    <div className={`our-advantage-item our-advantage-item-${i+1}`}>
                        <img src={img} alt=""></img>
                        <h2>{subDef[0]}</h2>
                        <span>{subDef[1]}</span>
                    </div>
                </Col>
            )
        }

        return (
            <div id="OurAdvantage">
                <div className={"container"} id={"OurAdvantage-container"}>

                    <div style={{width: "100%"}}>

                        <Row align={"middle"} justify={"center"}>

                            <Col span={24}>

                                <div className="our-advantage-title-div">
                                    <h2>{def.title}</h2>
                                    {/*这里超过换行*/}
                                    <span>{def.desc}</span>
                                </div>
                            </Col>

                            <div style={{width: "100%"}}>
                                <Row justify={"center"} align={"top"}
                                     gutter={[{ xs: 0, sm: 0, md: 24, lg: 32 },{ xs: 0, sm: 0, md: 24, lg: 32 }]}>
                                    {elementList}
                                </Row>
                            </div>

                        </Row>

                    </div>
                </div>
            </div>
        );
    }
}