import React from "react";
import "./ProductDesc.css";
import {Col, Row, Spin} from "antd";
import {GameShowDef} from "../../config/GameShowDef";
import {gameShowConfig} from "../../config/xls";

/*
 * 产品详情页
 * @author shawn
 */
export default class ProductDesc extends React.Component<any, any> {

    constructor(props:any) {
        super(props);
        // console.log("进来初始化了");
        //获取产品ID
        // let id = this.props.match;
        // console.log(id);
        this.state = {
            id: 0,
            loadingBanner: true
        }
    }

    // componentDidMount(): void {
    //     let id = +this.props.match.params.id;  //产品ID，转为整数
    //     console.log(id);
    // }

    componentWillMount(): void {
        //获取产品ID
        // 注意: 这个在构造函数中取不到
        let id = +this.props.match.params.id;  //产品ID，转为整数
        // console.log(id);
        this.setState({id});
    }

    onLoadedBanner = () => {
        this.setState({
            loadingBanner: false
        })
    };

    render() {
        let def:GameShowDef|undefined = gameShowConfig.getItem(this.state.id);
        // console.log("看下");
        // console.log(def);
        //没有返回空
        if (!def) {
            return <div/>
        }

        let topBanner = require(`../../assets/images/product/top_banner/top_banner_${def.res}.png`);
        let imgIcon = require(`../../assets/images/product/icon/icon_${def.res}.png`);
        let imgTitle = require(`../../assets/images/product/480X240/title_bg_${def.res}.png`);
        let leftBanner = require(`../../assets/images/product/bottom_banner/bottom_banner_${def.res}_1.png`);
        let rightBanner = require(`../../assets/images/product/bottom_banner/bottom_banner_${def.res}_2.png`);

        let isShowWechat:boolean = def.tips.indexOf("wechat") !== -1;
        let isShowQQ:boolean = def.tips.indexOf("qq") !== -1;
        let isShowTikTok:boolean = def.tips.indexOf("tiktok") !== -1;

        return (
            <div id="ProductDesc">
                {/*游戏banner图*/}
                <Spin size="large" tip={"loading"} spinning={this.state.loadingBanner} delay={150}>
                    <div className="ProductDesc-game-banner-image-div">
                        <img src={topBanner} alt="" className="ProductDesc-game-banner-image" onLoad={this.onLoadedBanner}/>
                    </div>
                </Spin>


                <div className="container" >
                    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },{ xs: 8, sm: 16, md: 24, lg: 32 }]} justify="start"
                        align={"middle"}
                    >
                        <Col  span={6} lg={4}>
                            <img src={imgIcon} alt="" style={{
                                width: "100%",
                                marginTop: "-25%",
                            }}/>
                        </Col>
                        <Col span={18} lg={20}>
                            <div >
                                <h2 className="ProductDesc-title-text">{def.name}</h2>
                                <div className="ProductDesc-title-tags">

                                    <div className="ProductDesc-title-tags-item" style={{
                                        display: `${isShowWechat ? "block":"none"}`
                                    }}>微信小游戏</div>
                                    <div className="ProductDesc-title-tags-item" style={{
                                        display: `${isShowQQ ? "block":"none"}`
                                    }}>QQ玩吧</div>
                                    <div className="ProductDesc-title-tags-item" style={{
                                        display: `${isShowTikTok ? "block":"none"}`
                                    }}>抖音/头条</div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className={"global-title-text"}>游戏介绍</div>

                    <div style={{marginTop: "0.2rem"}}>
                        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },{ xs: 8, sm: 16, md: 24, lg: 32 }]} justify="center"
                             align={"top"}
                        >
                            {/*游戏名字图片*/}
                            <Col span={12} >
                                <img src={imgTitle} alt="" style={{width: "100%", backgroundSize: "cover"}}></img>
                            </Col>

                            <Col span={12}  >
                                <div className="ProductDesc-introduce-right">
                                    {/*标题*/}
                                    <h2>{def.name}</h2>
                                    {/*游戏内容介绍*/}
                                    {/*<span style={{background:"red"}}>{def.detailDesc}</span>*/}
                                    <p>{def.detailDesc}</p>
                                </div>
                            </Col>

                        </Row>
                    </div>

                    <div style={{marginTop: "0.35rem"}}>
                        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },{ xs: 8, sm: 16, md: 24, lg: 32 }]} justify="center"
                             align={"top"}
                        >
                            {/*游戏名字图片*/}
                            <Col span={12} >
                                <img src={leftBanner} alt="" style={{width: "100%"}}></img>
                            </Col>
                            <Col span={12} >
                                <img src={rightBanner} alt="" style={{width: "100%"}}></img>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}