import React from "react";
import "./Product.css";
import {Col,  Row} from "antd";
import ProductInfoBox from "./ProductInfoBox";
import {Link} from "react-router-dom";
import {gameShowConfig} from "../../config/xls";
import {GameShowDef} from "../../config/GameShowDef";
import imgProductTitle from "../../assets/images/ui/img_product_title.png";

/**
 * 游戏产品页面
 * @author shawn
 */
export default class Product extends React.Component {

    render() {
        let dataList = gameShowConfig.toArray((a, b) => a.sort - b.sort);
        let elements = [];
        for (let i = 0, len = dataList.length; i < len; i++) {
            // elements.push(<Link to="/product/1" key={`${i+1}`}><ProductInfoBox/></Link>);
            let def:GameShowDef = dataList[i];
            elements.push(
                <Col md={8} span={12} key={`${def.id}`}>
                    <Link to={`/product/${def.id}`}  ><ProductInfoBox def={def}/></Link>
                </Col>
            )
        }
        
        return (
            <div id={"product"}>
                <div className="container">
                    <div className="product-img-title-div">
                        <img src={imgProductTitle} alt="" style={{maxWidth: "100%"}}></img>
                    </div>

                    <Row justify="start" gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                        {elements}
                    </Row>
                </div>
            </div>
        );
    }
}