import {BaseDef} from "../config/BaseDef";
import {number, string} from "./Converters";


/**
 * 岗位配置定义
 * @author shawn
 */
export class PositionDef extends BaseDef({
    id: number(),
    type: string(),
    title: string(),
    duty: string(),
    request: string(),
    degree: string(),  //学历
    workLimit: string(),  //工作年限
    tips: string(),  //全职|兼职
    position: string(),  //工作地点
      //推荐用同岗位随机就好
    recommend: number.default(0)
    },
) {

}