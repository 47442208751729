import React from "react";
import "./MiniGamePlan.css";
import {pageCooperationConfig} from "../../config/xls";


/**
 * 提供小游戏专业发行方案
 * @author shawn
 */
export default class MiniGamePlan extends React.Component {

    render(): React.ReactNode {

        // let imgBg = require("../../assets/images/cooperation/38-1.png");

        let def = pageCooperationConfig.getItem(1);
        if (!def) {
            return <div />;
        }
        // let imgHexagon = require("../../assets/images/cooperation/hexagon.png");

        // let elementList = [];
        let elementList = def.desc.split("|").map((value, index) => {
            if (value !== '') {
                // return <li key={index}><img src={imgHexagon} alt={""}/>&nbsp;&nbsp;{value}</li>
                return <li key={index}>●&ensp;{value.trim()}</li>
            }
            return <li key={index}></li>
        });


        return (
            <div id="MiniGamePlan">
                {/*提供小游戏专业发行方案*/}
                {/*背景图*/}
                {/*<img src={imgBg} alt="" className="MiniGamePlan-image-bg" />*/}

                <div className={"container"} style={{
                    display: "flex",
                    // alignContent: "center",
                    alignItems: "center",
                    // justifyContent: "start",
                    // backgroundColor: "red",
                    height: "100%"
                }} id={"MiniGamePlan-content"} >

                    <div style={{marginTop: "-1rem"}} >
                        <p className="MiniGamePlan-title">{def.title || ""}</p>
                        <ul className="MiniGamePlan-item-list">
                            {elementList}
                        </ul>
                    </div>

                </div>

            </div>
        );
    }
}