import React from "react";
import "./Position.css";
import SearchBox from "./SearchBox";
import PositionDesc from "./PositionDesc";
import {positionConfig} from "../../config/xls";
import {List} from "antd";
import JobListItem from "./JobListItem";
import Scroller from "../test/Scroller";

/**
 * 岗位详情描述、
 * @author shawn
 */
export default class Position extends React.Component<any, any> {

    constructor(props:any) {
        super(props);
        this.state = {
            id: 0
        }
    }

    componentWillMount(): void {
        //获取产品ID
        // 注意: 这个在构造函数中取不到
        let id = +this.props.match.params.id;  //产品ID，转为整数
        this.setState({id})
    }

    render(): React.ReactNode {

        let def = positionConfig.getItem(this.state.id);
        if (!def) {
            return <div/>;
        }

        let dataList = positionConfig.toArray().filter(value => value.type === def?.type);
        
        let prefixClass = "Position-bg-container";

        if (def.type !== "") {
            prefixClass = prefixClass + ` Position-bg-container-${def.type}`
        }
        
        return (
            <div id="Position">
                {/*头部背景图*/}
                <div className={`${prefixClass}`}>
                    <div className={"container Position-top-container"}>
                        <SearchBox />
                    </div>
                </div>

                <div className={"container"}>

                    {/*职位介绍*/}
                    <PositionDesc id={this.state.id}/>

                    {/*相关推荐*/}
                    <div style={{marginLeft: "5%", marginTop: "0.5rem"}} id={"position-recommend"}>
                        <div className={"global-title-text"}>相关职位推荐</div>

                        <div style={{height: "4rem", overflow: "hidden"
                        }}
                        >
                            <Scroller speed={100} content={
                                <List
                                    itemLayout="vertical"
                                    dataSource={dataList}
                                    renderItem={item => (
                                        <JobListItem def={item}/>
                                    )}
                                />
                            }>
                            </Scroller>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}