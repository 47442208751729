import React from "react";
import "./PositionDesc.css"
import {PositionDef} from "../../config/PositionDef";
import {positionConfig} from "../../config/xls";
import TimeUtils from "../../utils/TimeUtils";
import {Col, Row} from "antd";
import {withRouter} from "react-router";

/**
 * 职位详细描述
 * @author shawn
 */
class PositionDesc extends React.Component<any, any> {

    constructor(props:any) {
        super(props);
        this.state = {
            id: this.props.id
        }
    }

    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        let id = +nextProps.match.params.id;
        if (id > 0) {
            this.setState({id});
        }
    }

    render(): React.ReactNode {
        let def:PositionDef|undefined = positionConfig.getItem(this.state.id);
        if (!def) {
            return <div/>
        }
        // let date = "2020年3月27日更新"
        let dateStr = TimeUtils.getTodayYYYYMMDD() + "更新"
        let info = `${def.tips} | ${def.position} | ${def.degree} | ${def.workLimit} | ${dateStr}`;
        let duty = def.duty;

        /*duty根据分割线换行*/
        let element = duty.split("\n").map((value, index) => `${value}`);
        // let element = duty.split("\n").map((value, index) => `${value}`);
        let dutyNode = [];
        for (let i = 0, len = element.length; i < len; i++) {
            // dutyNode.push(element[i], <br/>);
            dutyNode.push(<div key={`${i+1}`}>{element[i]}</div>);
        }
        //要求
        let request = def.request;
        // element = request.split("|").map((value, index) => `${index+1}. ${value}`);
        element = request.split("\n").map((value, index) => `${value}`);
        let requireNode = [];
        for (let i = 0, len = element.length; i < len; i++) {
            // requireNode.push(element[i], <br/>);
            requireNode.push(<div key={`${i+1}`}>{element[i]}</div>);
        }
        return (
            <div id="PositionDesc">

                {/*职位标题信息*/}

                <div className="PositionDesc-title">
                    {/*标题*/}

                    <Row align={"middle"} justify={"space-between"}>
                        <Col span={12} md={{span:10}}>
                            <div className="PositionDesc-title-text">
                                {def.title}
                            </div>
                        </Col>

                        <Col span={12} md={{span: 14}}>
                            <div className="PositionDesc-info-email">
                                [申请职位]   请投递邮箱hr@h5microgame.com
                            </div>
                        </Col>
                    </Row>

                    {/*<div className="PositionDesc-title-text">*/}
                        {/*{def.title}*/}
                    {/*</div>*/}

                    {/*基础信息*/}
                    <div className="PositionDesc-info-text">
                        {info}
                    </div>

                    {/*邮箱投递信息*/}
                    {/*<div className="PositionDesc-info-email">*/}
                        {/*[申请职位]请投递邮箱XXXXXXXXXXXX@xx.com*/}
                    {/*</div>*/}
                </div>

                {/*职位描述*/}
                <div className="PositionDesc-duty">
                    <div className="PositionDesc-left-title">职位描述</div>
                    <div className="PositionDesc-desc-text">{dutyNode}</div>
                </div>

                {/*职位要求*/}
                <div className="PositionDesc-requirement">
                    <div className="PositionDesc-left-title">职位要求</div>
                    <div className="PositionDesc-desc-text">{requireNode}</div>
                </div>
            </div>
        );
    }
}

export default withRouter(PositionDesc);