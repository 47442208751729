import React from "react";
import "./ProductInfoBox.css";
import {Spin} from "antd";
import {GameShowDef} from "../../config/GameShowDef";

/*更多按钮*/
import btnMore from "../../assets/images/ui/btn_more.png";

/**
 * 单个产品说明
 * @author shawn
 */
export default class ProductInfoBox extends React.Component<any, any> {

    constructor(props:any) {
        super(props);
        this.state = {
            loading: true,
            loadingIcon: true,
            def: this.props.def  //定义
        }
    }

    /*图片加载完成事件*/
    onImageLoaded = () => {
        this.setState({
            loading: false
        })
    };

    render() {
        let def:GameShowDef = this.state.def;

        /*获取图片路径*/
        let bgUrl = require(`../../assets/images/product/380X220/card_bg_${def.res}.png`);
        let iconUrl = require(`../../assets/images/product/380X220/card_icon_${def.res}.png`);

        return (
            <div className="product-info-box" style={{
                position: "relative",
            }}>
                {/*加载图片加入loading*/}
                <Spin size="large" tip="loading" spinning={this.state.loading} delay={150} style={{
                    minHeight: "1rem",
                }}>
                    {/*<div>*/}
                        <img src={bgUrl} alt="" className='product-info-image-bg' onLoad={this.onImageLoaded} />
                    {/*</div>*/}
                    {/*游戏信息*/}
                </Spin>

                <div className="product-game-info">
                    {/*游戏小logo*/}
                    <img className="product-game-info-logo" alt="" src={iconUrl} />

                    <h3 className="product-game-info-title">{def.name}</h3>

                    <div className="product-game-hide-info">
                        <p className="product-game-info-desc">{def.desc}</p><br/>
                        <img className="product-game-info-logo-btn-more" alt="" src={btnMore} />
                    </div>
                </div>

            </div>
        );
    }
}