import React from "react";
import "./JoinProcess.css";
import {Col, Row} from "antd";
import {pageCooperationConfig} from "../../config/xls";

/**
 * 合作流程
 * @author shawn
 */
export default class JoinProcess extends React.Component {

    render(): React.ReactNode {

        let def = pageCooperationConfig.getItem(3);
        if (!def)  {
            return <div></div>
        }

        // console.log(def);

        let img = require("../../assets/images/cooperation/42.png");
        let imgJoinProcess = require("../../assets/images/cooperation/img_join_process.png");
        // let imgJoinProcessMobile = require("../../assets/images/cooperation/img_join_process_mobile.png");

        return (
            <div id="JoinProcess">
                {/*合作流程*/}

                <div id={"imgJoinProcessMobile"}>
                    {/*<img src={imgJoinProcessMobile} className={"join-process-image-mobile"} alt={""}></img>*/}
                </div>

                <div className={"container"}
                    style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%"
                    }}
                >

                    <Row align={"top"} style={{width: "100%"}}>
                        <Col span={0} md={24}>
                            <div className="JoinProcess-title">{def.title}</div>
                        </Col>

                        <Col span={0} md={24}>
                            <div>
                                <img src={imgJoinProcess} className={"join-process-image"} alt={""}></img>
                            </div>

                        </Col>

                        {/*<Col span={24}>*/}

                            {/*<div className="JoinProcess-info-content">*/}
                                {/*<Row>*/}
                                    {/*<Col span={6} className="JoinProcess-info-desc">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/}
                                    {/*</Col>*/}
                                    {/*<Col span={6} className="JoinProcess-info-desc"> xxxxxxxxxxxxxxxxxxxxxxxx*/}
                                    {/*</Col>*/}
                                    {/*<Col span={6} className="JoinProcess-info-desc">xxxxxxxxxxxxxxxxxxxxxxxx*/}
                                    {/*</Col>*/}
                                    {/*<Col span={6} className="JoinProcess-info-desc">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/}
                                    {/*</Col>*/}
                                {/*</Row>*/}
                            {/*</div>*/}
                        {/*</Col>*/}


                    </Row>

                </div>
            </div>
        );
    }
}