import React from "react";
import "./JobTypeView.css";
import SearchBox from "./SearchBox";
import JobCategory from "./JobCategory";
import {Col, List, Row} from "antd";
import {positionConfig} from "../../config/xls";
import JobListItem from "./JobListItem";

/*岗位类别*/
// enum JobType {
//     Develop = "develop",
//     Art = "art",
//     Design = "design",
//     Market = "market"
// }

/**
 * 根据岗位分类获得的结果
 * @author shawn
 */
export default class JobTypeView extends React.Component<any, any> {

    constructor(props:any) {
        super(props);
        this.state = {
            type: "",    //岗位类别
            data: [],    //所有职位
        }
    }

    componentWillMount(): void {
        // 获取岗位分类
        // 注意: 这个在构造函数中取不到
        let type = this.props.match.params.type;
        this.setState({type})
    }

    /*点击类别切换*/
    componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
        let type = nextProps.match.params.type;
        this.setState({type})
    }

    /*根据类型获取标题名字*/
    getTypeName(type: string):string {
        if (type === 'develop') {
            return "技术&开发"
        }
        else if (type === 'art') {
            return "美术&设计"
        }
        else if (type === 'design') {
            return "策划&产品"
        }
        else if (type === 'market') {
            return "运营&市场"
        }
        return "技术&开发";
    }

    render(): React.ReactNode {
        /*数据准备*/
        let type = this.state.type;
        let typeName = this.getTypeName(type);
        let dataList = positionConfig.filter(def => def.type === type);
        let count = `共显示${dataList.length}个职位`;
        //
        // let url = `../../assets/images/jobs/recruit_480_${type}.png`;
        // if (type === 'market')  {
        //     url = `../../assets/images/jobs/recruit_480_game.png`;
        // }

        let prefixClass = "JobTypeView-bg-container ";

        if (type !== '') {
            prefixClass = prefixClass + `JobTypeView-bg-container-${type}`;
        }
        // require("../../assets/images/jobs/recruit_480_art.png")
        // console.log(url);
        // let imgBg = require(`${url}`);
        // console.log(imgBg);

        return (
             <div id="JobTypeView">
                <div className={`${prefixClass}`}>
                    <div className={`container JobTypeView-top-container`}>
                        {/*<div className="JobTypeView-top-container">*/}
                            <SearchBox />
                            <JobCategory type={this.state.type} /> {/*将类型传入*/}
                        {/*</div>*/}
                    </div>
                </div>

                {/*岗位容器*/}
                <div style={{background:"rgb(246, 246, 246)"}}>
                    <div className={"container"}>
                        <Row>
                            <Col span={24}>
                                <div className="JobTypeView-position-container">
                                    <div className="JobTypeView-position-container-title">
                                        <span>{typeName}</span>
                                        <span>{count}</span>
                                    </div>

                                    {/*岗位列表渲染*/}
                                    <div className="JobTypeView-position-list-container">
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={dataList}
                                            renderItem={item => (
                                                <JobListItem def={item}/>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}