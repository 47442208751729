import './HomeGameCarousel.less';
import "../../assets/images/home/game_card/sprites.css";
import React from "react";

/**
 * 首页游戏列表无限轮播
 * @author shawn
 */
class HomeGameCarousel extends React.Component<any, any> {

    // state = {
    //     display: true,
    //     width: 100
    // };
    //
    // constructor(props:any) {
    //     super(props);
    //     this.play = this.play.bind(this);
    //     this.pause = this.pause.bind(this);
    // }
    //
    // play() {
    //     this.slider.slickPlay();
    // }
    // pause() {
    //     this.slider.slickPause();
    // }

    // stopScroll = () => {
    //     console.log("出去拉拉阿鲁");
    // };
    //
    // startScroll = () => {
    //     console.log("进来拉啊");
    // };

    render() {

        // const settings = {
        //     dots: false,
        //     arrows: false,
        //     infinite: true,
        //     slidesToShow: 7,
        //     slidesToScroll: 1,
        //     speed: 1600,
        //     autoplaySpeed: 200,
        //     cssEase: "ease-in-out",
        //     pauseOnHover: true,
        //     autoplay: true,
        //
        //     // onLazyLoad(slidesToLoad: number[]): void {
        //     //     console.error("xxxx")
        //     //     console.log(slidesToLoad)
        //     // }
        //
        //     // touchMove: false,
        //
        //     // edgeFriction: -200
        //
        //     // useCSS: false,
        //     // useCSS: true,
        //     // slidesPerRow: 1,
        //     // rows: 1,
        //     // edgeFriction: 0,
        //     // variableWidth: true,
        //     // pauseOnFocus: true,
        //     // swipeToSlide: true,
        //     // touchm
        //     // draggable: true,
        //     // waitForAnimate: false,
        //
        //     // onEdge(swipeDirection: SwipeDirection): void {
        //     // }
        // };
        //
        // let elementList = [];
        //
        // for (let i = 0; i < 9; i++) {
        //     elementList.push(
        //         <div key={`${i+1}`}>
        //             <div className='HomeGameCarousel-item'>
        //                 <img src='./images/home_games/game_1.png' alt=""></img>
        //             </div>
        //         </div>
        //     )
        // }

        const ITEM_COUNT = 38;

        let elementList = [];
        for (let i = 1; i < ITEM_COUNT; i++) {
            // let source = require(`../../assets/images/home/game_card/img_achievement_icon_${i}.png`)
            elementList.push(
                  <li className={"one-item"} key={`${i}`}>
                      {/*<img src={source} alt="" />*/}
                      <div className={`img_achievement_icon_${i}`}></div>
                  </li>
            )
        }
        for (let i = 1; i < ITEM_COUNT; i++) {
            // let source = require(`../../assets/images/home/game_card/img_achievement_icon_${i}.png`)
            elementList.push(
                <li className={"one-item"} key={`${i + ITEM_COUNT}`}>
                    {/*<img src={source} alt="" />*/}
                    <div className={`img_achievement_icon_${i}`}></div>
                </li>
            )
        }

        return (
            <div id={"HomeGameCarousel"}>
                <div className={"container"}>
                    <div className={"wrap"} >
                        <ul className="list">
                            {elementList}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeGameCarousel;