import React from "react";
import "./SearchBox.css";
import {SearchOutlined} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import {message} from "antd";

/**
 * 职位搜索框
 * @author shawn
 */
class SearchBox extends React.Component<any, any> {

    constructor(props:any) {
        super(props);

        /**/
        this.state = {
            inputValue: ""
        }
        // console.log("这是什么鬼");
        // console.log(this.props)
    }
    // componentDidMount(): void {
    //     console.error("SearchBox xxxx");
    //     console.log(this.props)
    // }

    componentDidMount(): void {
        message.config({
            top: 70,
            duration: 2,
            maxCount: 2,
        });
    }

    componentWillUnmount(): void {
        message.destroy()
    }

    render(): React.ReactNode {
        return (
            <div id="SearchBox">
                {/*<form action="/search">*/}
                    <input type="text" className="SearchBox-input" placeholder="搜索工作岗位"
                           onKeyDown={this.onKeyDown} value={this.state.inputValue} onChange={this.onInputChange}>
                    </input>

                    {/*搜索ICON*/}
                    <SearchOutlined className="SearchBox-icon"/>

                    {/*<Link to={"/search"} state:{this.state.inputValue}>*/}
                    <div className="SearchBox-search-button" onClick={this.onBtnSearchClick}>
                        查看岗位>
                    </div>
                    {/*</Link>*/}
                {/*</form>*/}
            </div>
        );
    }

    onInputChange = (e:any) => {
        this.setState({
            inputValue:  e.target.value
        });
    };

    onBtnSearchClick = (e:any) => {
        let value = this.state.inputValue;
        this.onSearch(value);
    };

    onSearch = (value:string) => {

        if (!value || value.length <= 0) {
            // message.config({
            //     top: 60,
            //     duration: 2,
            //     maxCount: 3,
            // });
            message.warn("请先输入关键词")
            return;
        }

        /*第一版不打开新页面*/
        this.props.history.push('/search', {
            keyword: value
        });

        // value = encodeURI("/search?key=" + value);
        // console.log(encodeURIComponent(value))
        // let key = encodeURIComponent(value)
        // console.log(encodeURIComponent(value))
        // /*第二版打开新页面*/
        // window.open("/search?key=" + key);
        // encodeURIComponent()

        // let win = window.open("/search?key=" + encodeURIComponent(value), "_blank");
        // if (win) {
        //     win.focus();
        // }
    };

    onKeyDown = (e: any) => {
        //敲入回车键时执行
        if (e.keyCode === 13) {
            let value = e.target.value;
            this.onSearch(value);
        }
    }
}

/*关键用withRouter包装下，获取history*/
export default withRouter(SearchBox);