import React from "react";
import "./HomeGames.css";
import HomeGameCarousel from "./HomeGameCarousel";
import {Button} from "antd";
import {Link} from "react-router-dom";

/**
 * 首页游戏展示轮播
 * @author shawn
 */
class HomeGames extends React.Component {

    render() {
        return (

            <div id='home-games'>

                <div className='home-games-container'>

                    <div className='home-games-container-top'>

                        {/*GAME透明字*/}
                        <span className='home-games-container-top-span-game'>
                            GAME
                        </span>

                        {/*我们的游戏*/}
                        <span className='home-games-container-top-span-our-game'>
                            我们的游戏
                            <div className='home-games-container-top-span-underline'></div>
                        </span>

                        {/*下划线*/}
                        {/*<div className='home-games-container-top-span-underline'>*/}
                        {/*</div>*/}

                    </div>

                    <div className='container'>
                        <HomeGameCarousel/>
                    </div>

                    <div className='home-games-container-bottom'>
                        <Link to="/product">
                            <Button style={{
                                fontSize: "0.2rem",
                                textAlign: "center",
                                lineHeight: "0.2rem",
                            }} shape="round" size="large">
                                查看更多
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeGames;