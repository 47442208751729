import React from "react";
import "./Contact.css";
import {Col, Row, Spin} from "antd";
import { Map, Marker } from 'react-amap';
import imgContactWays from "../../assets/images/ui/img_contact_ways.png";
import imgMark from "../../assets/images/ui/map_marker_icon.png";

/**
 * 联系我们主页
 * @author shawn
 */
export default class Contact extends React.Component<any,any> {

    // toolEvents:any;
    // tool:any;
    // 拾取坐标网址：高德的
    //https://lbs.amap.com/console/show/picker
    // mapCenter =  {longitude: 121.39629, latitude: 31.171715};  //民润大厦位置
    // markerPosition = {longitude: 121.402889, latitude: 31.177471};
    //121.402889,31.177471
    // 31.1708560000,121.3962920000

    constructor(props:any) {
        super(props);
        this.state = {
            loadingImgAddress: true,
            loadingMap: true
        }
    }

    onLoadedImgAddress = () => {
        this.setState({
            loadingImgAddress: false
        })
    }

    render() {

        // 自定义marker标志 把标志变成红色
        const customMarkerStyle:any = {
            // background: `url('http://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/map-marker-icon.png')`, //标记图片地址
            background: `url(${imgMark})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '30px',
            height: '40px',
            color: '#000',
            textAlign: 'center',
            lineHeight: '40px',
            marginLeft: '-6px',
        };

        // let customMarkerStyle:any =  <EnvironmentOutlined style={{color: "#ff0000"}}/>;
        const events = {
            created: (ins:any) => {
                this.setState({
                    loadingMap: false
                });
            },
            // click: () => {console.log('You Clicked The Map')}
        };
        // const Loading = <div style={loadingStyle}>Loading Map...</div>
        const Loading = <Spin size={"large"} tip={"加载地图中..."} spinning={this.state.loadingMap} style={{
            left: "40%",
            top: "40%",
            position: "relative"
        }}/>

        let imgCompany = require("../../assets/images/ui/company.png");

        return (

            <div className="container" >

                    {/*公司地址*/}
                    <div className="contact-company-address">

                        {/*title 公司标题*/}
                        {/*<div className="contact-company-address-title">公司地址</div>*/}
                        <div className="global-title-text">公司地址</div>
                        <div>

                        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
                            {/*公司图片*/}
                            <Col span={24} md={12}>
                                <Spin size={"large"} tip={"加载图片..."} spinning={this.state.loadingImgAddress} delay={150}>
                                    <img src={imgCompany} alt="" className='contact-company-address-left-image'
                                        onLoad={this.onLoadedImgAddress}
                                    ></img>
                                </Spin>
                            </Col>

                            {/*/!*地图模块*!/*/}
                            <Col span={24} md={12}>
                                {/*<Spin size={"large"} tip={"加载地图中..."} spinning={this.state.loadingMap} delay={100} style={{*/}
                                    {/*width: "100%",*/}
                                    {/*height: "300px"*/}
                                {/*}}>*/}
                                    <div className="contact-company-address-right">
                                            <Map amapkey={'788e08def03f95c670944fe2c78fa76f'}
                                                 // center={{longitude: 31.17085, latitude: 121.39629}}
                                                 zoom={16.5}
                                                 center={{longitude: 121.396347, latitude: 31.171715}}
                                                 plugins={['ToolBar']}
                                                 events={events}
                                                 loading={Loading}
                                            >
                                                <Marker position={{longitude: 121.396347, latitude: 31.171715}} >
                                                    <div style={customMarkerStyle}></div>
                                                </Marker>
                                            </Map>
                                    </div>
                                {/*</Spin>*/}
                            </Col>
                        </Row>
                        </div>
                    </div>

                    {/*联系方式*/}
                    <div className="contact-ways">
                        {/*<div className="contact-company-address-title">联系方式</div>*/}
                        <div className={"global-title-text"}>联系方式</div>

                        {/*/!*<div className="contact-company-ways-image">*!/*/}

                        <div style={{textAlign: "center", marginTop: "0.15rem", marginBottom:"0.05rem"}}>
                            <img src={imgContactWays} alt={""} style={{
                                maxWidth: "100%",
                                width: "auto"
                            }}></img>
                        </div>

                        {/*<Row>*/}
                            {/*<Col span={1}>*/}

                            {/*</Col>*/}
                            {/*<Col span={22}>*/}
                                {/*<img src="./images/img_contact_ways.png" alt="" className="contact-company-ways-image"></img>*/}
                            {/*</Col>*/}

                            {/*<Col span={1}>*/}
                            {/*</Col>*/}
                        {/*</Row>*/}
                        {/*<Row justify={"space-between"} gutter={[16, 16]}>*/}
                            {/*<Col span={8} className="contact-company-ways-text" style={{background: "red"}}>*/}
                                {/*XXXXXXXX@qq.com*/}
                            {/*</Col>*/}
                            {/*<Col span={8} className="contact-company-ways-text" style={{background: "red"}}>*/}
                                {/*11111111111111*/}
                            {/*</Col>*/}
                            {/*<Col span={8} className="contact-company-ways-text" style={{background: "red"}}>*/}
                                {/*111111111111*/}
                            {/*</Col>*/}
                        {/*</Row>*/}
                    </div>
            </div>
        );
    }
}
