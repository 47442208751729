import React from "react";
import "./Cooperation.css";
import MiniGamePlan from "./MiniGamePlan";
import OurAdvantage from "./OurAdvantage";
import JoinProcess from "./JoinProcess";

//忽略.d文件定义
//@ts-ignore
import ReactFullpage from '@fullpage/react-fullpage';

// class MySection extends React.Component<any, any> {
//     render() {
//         return (
//             <div className="section">
//                 <h3>{this.props.content}</h3>
//             </div>
//         );
//     }
// }

/**
 * 商务合作页面
 * @author shawn
 */
class Cooperation extends React.Component<any, any> {

    // constructor(props:any) {
    //     super(props);
    //
    //     console.log("===Cooperation======");
    //     console.log(props);
    //
    //     console.log("这是测试");
    //     console.log(this.props.location.state.mmp);
    //     // this.props.location.state.mmp;  //这里获得
    // }

    
    render() {
        const anchors = ["提供专业方案", "我们的优势", "合作流程"];
        // const fullPage = require("react-fullslip");
        // const {FullSlip,SlipItem} = fullPage;

        // console.log(ReactFullpage);

        // console.log(window["ReactFullpage"])

        // let xxx = window["ReactFullpage"]

        // import ReactFullpage from "@fullpage/react-fullpage";

        // console.error("xxxxxxxxx")
        // console.log(FullSlip);
        // console.log(SlipItem);
        // let options = {
        //     navigation: true,           //是否开启导航点,      默认为true
        //     activeClass: 'active',      //自定义导航点类名,    默认为active, .navigation-dot下的.active
        //     duration:1000,              //屏幕滑动切换的时间,  默认为1000
        //     transverse:false,            //是否更改为横向滑动,  默认为false
        //     // navImage:[require('./assets/1.jpg'),require('./assets/2.jpg'),require('./assets/3.jpg')]    //导航点图片,可选,默认无图片
        //     arrowNav:true              //是否开启箭头导航     默认false不开启
        // };

        return (
            <div id="Cooperation">

                {/*改为全屏翻页*/}
                {/*todo添加箭头*/}

                <ReactFullpage
                    controlArrows
                    anchors={anchors}
                    navigation
                    navigationTooltips={anchors}
                    sectionsColor={["#282c34", "#ff5f45", "#0798ec"]}
                    onLeave={(origin:any, destination:any, direction:any) => {
                        // console.log("onLeave event", { origin, destination, direction });
                    }}
                    // render={({ state, fullpageApi }) => {
                    render={() => {
                        // console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console
                        return (
                            <div>
                                {/*<MySection content={"Slide down!"} />*/}
                                <div className={"section"}><MiniGamePlan /></div>
                                {/*<MySection content={"Keep going!"} />*/}
                                <div className={"section"}><OurAdvantage/></div>
                                {/*<MySection content={"Slide up!"} />*/}
                                <div className={"section"}><JoinProcess/></div>
                            </div>
                        );
                    }}
                />
                {/*为小游戏提供专业的发行方案*/}
                {/*<MiniGamePlan/>*/}

                {/*/!*我们的优势*!/*/}
                {/*<OurAdvantage/>*/}

                {/*/!*合作流程*!/*/}
                {/*<JoinProcess/>*/}

                {/*企业大事件*/}
                {/*<CompanyTimeline />*/}

                {/*合作伙伴*/}
                {/*<Partner/>*/}
            </div>
        )
    }
}


// export default withRouter(Cooperation);
export default Cooperation;
